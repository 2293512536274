<template>
  <div>
    <b-overlay :show="show" opacity="0.95" variant="dark">
     
    <b-row>
      
      <b-col cols="12" >
        <b-card>
          <add @loaddata="loaddata"></add>
          <edit @loaddata="loaddata" ref="modal"></edit>
          <show ref="modalshow"></show><br /><br />
         
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="show_data(data.item.id)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item>
                <b-dropdown-item @click="edit_data(data.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="delete_data(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    
    </b-row>
    <template v-slot:overlay>
      <div class="overlay-alert">สำหรับแพ็กเกจ Standard ขึ้นไป</div>
    </template>
  </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BOverlay
} from "bootstrap-vue";
import api from "@/api";
import add from "./Add.vue";
import edit from "./Edit.vue";
import show from "./Show.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    add,
    edit,
    show,
    BOverlay
  },
  data() {
    return {
      show: true,
      isAddCampaign: false,
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "name", label: "TAG" },
        { key: "total", label: "ผู้ติดตาม", sortable: true },
        { key: "actions" },
      ],
      /* eslint-disable global-require */
      items: [],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    if (localStorage.getItem("packages") === "Pro") {
      this.show = false;
    } else if (localStorage.getItem("packages") === "Standard") {
      this.show = false;
    } else {
      this.show = true;

    }

    this.loaddata();
  },
  methods: {
    async loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("/taglist", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.log(response.data);
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    delete_data(id) {
      console.log("delete", id);
      const params = {
        id: id,
        store_id: localStorage.getItem("store_id"),
      };
      this.$swal({
        title: "คุณต้องการลบ TAG ?",
        text:'TAG จะถูกลบออกจากผู้ใช้งานทุกคนที่ติด TAG',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.post("/tagdel", params).then((response) => {
            console.log(response.data);
            this.loaddata();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "ลบสำเสร็จ",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    edit_data(id) {
      this.$refs.modal.showModaledit(id);
    },
    show_data(id) {
      this.$refs.modalshow.showModaldata(id);
    },
    show_tag(id) {
      this.$refs.modaladdtag.showmodeladdtag(id);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
